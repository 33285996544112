import { currentUser, pbClient } from '$lib/client/pocketbase';

try {
  pbClient.authStore.loadFromCookie(document.cookie);
} catch (_) {
  pbClient.authStore.clear();
}
pbClient.authStore.onChange(() => {
  currentUser.set(pbClient.authStore.model);
  const cookie = pbClient.authStore.exportToCookie({
    expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 30),
    httpOnly: false,
    secure: false
  });
  document.cookie = cookie;
  if (!pbClient.authStore.isValid) {
    document.cookie = 'pb_auth=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  }
}, true);
