import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63')
];

export const server_loads = [0,6,7,3,8];

export const dictionary = {
		"/(public)": [~9,[2]],
		"/admin/(restricted)": [31,[4,5]],
		"/admin/(restricted)/admins": [~32,[4,5]],
		"/admin/(restricted)/annual-reports": [~33,[4,5]],
		"/admin/(restricted)/bible-changelog": [~34,[4,5]],
		"/admin/(restricted)/bible-notes": [35,[4,5,6]],
		"/admin/(restricted)/bible-notes/[book=number]": [36,[4,5,6]],
		"/admin/(restricted)/bible-project/chapters": [~37,[4,5]],
		"/admin/(restricted)/bible-project/groups": [~38,[4,5]],
		"/admin/(restricted)/bible-text": [39,[4,5,7]],
		"/admin/(restricted)/bible-text/[book=number]": [40,[4,5,7]],
		"/admin/(restricted)/daily-quote": [~41,[4,5]],
		"/admin/(restricted)/donations": [~42,[4,5]],
		"/admin/(restricted)/donations/[year=number]": [~43,[4,5]],
		"/admin/(restricted)/donations/[year=number]/[month=number]": [~44,[4,5]],
		"/admin/(restricted)/donators": [~45,[4,5]],
		"/admin/(restricted)/global-values": [~46,[4,5]],
		"/admin/login": [53,[4]],
		"/admin/(restricted)/premium-files": [~47,[4,5]],
		"/admin/(restricted)/references": [~48,[4,5]],
		"/admin/(restricted)/reviews": [~49,[4,5]],
		"/admin/(restricted)/translators": [~50,[4,5]],
		"/admin/(restricted)/update-search": [51,[4,5]],
		"/admin/(restricted)/users": [~52,[4,5]],
		"/backend/newsletter": [54],
		"/backend/quote/register": [55],
		"/backend/registration": [56],
		"/backend/translation": [57],
		"/(public)/(modals)/(donations-callback)/chyba-pri-darovani": [10,[2,3]],
		"/confirm": [~58],
		"/confirm/newsletter/[token]": [~60],
		"/(public)/(modals)/confirm/quote/[token]": [~13,[2,3]],
		"/confirm/[token]": [~59],
		"/create-password/[user]/[token]": [61],
		"/(public)/(modals)/(donations-callback)/darovaci-udaje": [~11,[2,3]],
		"/(public)/(modals)/(donations-callback)/dekujeme": [12,[2,3]],
		"/(public)/en": [~14,[2]],
		"/(public)/join-us": [15,[2]],
		"/(public)/join-us/donate": [16,[2]],
		"/(public)/klub-bible21": [17,[2]],
		"/(public)/klub-bible21/darujte": [18,[2]],
		"/(public)/klub-bible21/registrace": [19,[2]],
		"/(public)/kontakt": [20,[2]],
		"/(public)/nadacni-fond": [~21,[2]],
		"/(public)/o-bibli21": [~22,[2]],
		"/(public)/ohlasy": [~23,[2]],
		"/online": [62,[8]],
		"/online/[slug]": [~63,[8]],
		"/(public)/password-reset": [~24,[2]],
		"/(public)/password-reset/[token]": [~25,[2]],
		"/(public)/podminky-zpracovani-osobnich-udaju": [26,[2]],
		"/(public)/prvni-prihlaseni": [~27,[2]],
		"/(public)/prvni-prihlaseni/[mail]": [~28,[2]],
		"/(public)/studijni-bible21": [29,[2]],
		"/[...rest]": [~30]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';